import * as React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = ['kukan', 'pana_100th', 'eneos', 'tgs_2016', 'tgs_2015', 'tgs_2014', 'tgs_2013', 'tgs_2012', 'canon_expo_2015', 'nec_future', 'yahoo_news', 'aoyama140', 'meiji_vr'];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        1982年東京生まれ。2005年入社。<br />
        国内外の大型イベントにおける展示映像やプレゼンテーション映像をはじめ、企業ブランディング映像やPRなどのベーシックなクライアントワークなども広く取り扱う。<br />
        ミラノデザインウィーク、CES、モーターショー、ゲームショウ、プライベート展示など国内外のプロジェクトに参画。<br />
        大小関わらず楽しむことがモットー。
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return null;
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
